import React, { FC } from 'react';
import { graphql } from 'gatsby';
import DangerouslySetInnerHtml from 'gatsby-theme-nurofen/src/components/common/DangerouslySetInnerHtml';
import { ISimpleListComponent } from './model';

import './SimpleListComponent.scss';

const SimpleListComponent: FC<ISimpleListComponent> = ({
  simpleListComponent,
}) => {
  return (
    <section>
      <DangerouslySetInnerHtml html={simpleListComponent} />
    </section>
  );
};

export const query = graphql`
  fragment FragmentSimpleListComponent on ISimpleListComponent {
    simpleListComponent
  }
`;

export default SimpleListComponent;

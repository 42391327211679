import React, { FC, useCallback, useState, useMemo, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { graphql, Link } from 'gatsby';

import { LABEL_DARK_FONT_TEXT, LABEL_LIGHT_FONT_TEXT } from 'utils/constants';

import useScreenRecognition from 'hooks/useScreenRecognition';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import NFButton from 'components/common/NFButton';
import SimplePopup from 'components/SimplePopup';
import RelatedElementsCarousel, {
  IRelatedElementsCarouselCalculated,
  IRelatedElementsCarouselData,
} from 'components/RelatedElementsCarousel';
import RelatedElementsList from 'components/RelatedElementsList';
import { parseNFButton } from 'components/common/NFButton/parsers';
import relatedElementsCarouselHelpers from './helpers';

import { ProductCardModifiedProps } from './model';
import 'components/ProductCard/ProductCard.scss';
import './ProductCardModified.scss';
import {
  WINDOW_PADDING,
  RELATED_ELEMENTS_TRIGGER_ID,
  DEFAULT_ITEM_WIDTH,
  MOBILE_VIEW_RELATED_ELEMENTS,
} from './constants';

const ProductCardOverride: FC<ProductCardModifiedProps> = ({
  label,
  labelBgColor,
  title,
  text,
  img,
  productRelatedImage,
  productRelatedImageAlt,
  classes,
  bg,
  link,
  fontColor,
  showLabels = false,
  isLazyLoading,
  descriptionExtended,
  footnotes,
  descriptionOverTheTitle,
  alt,
  defaultButtontext = 'View product',
  relatedElementsTrigger,
  relatedElements,
  showBuyNowButton,
  carouselControls,
}) => {
  const [isPopupVisible, setPopupVisible] = useState<boolean>(false);
  const [isShowScrollOnRelatedElements, setShowScrollOnRelatedElements] = useState<boolean>(false);
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);
  const [responsiveObj, setResponsiveObj] = useState<IRelatedElementsCarouselCalculated>({
    desktop: 0,
  });
  const elementRef = useRef<HTMLDivElement>(null);

  const { isDesktop, isLargeDesktop, isMobile, windowWidth } = useScreenRecognition();

  const handleCalculatedResponsiveObj = useCallback(
    (isDesktopMode: boolean | null, currentWidth: number) => () => {
      if (isDesktopMode) {
        setResponsiveObj({
          desktop: Math.floor((currentWidth - WINDOW_PADDING) / DEFAULT_ITEM_WIDTH),
        });
      }
    },
    []
  );

  const handleRelatedElementsVisibilityChange = useCallback(() => {
    setPopupVisible((oldValue) => !oldValue);
  }, []);

  const popupTriggerData = relatedElementsTrigger.properties;
  const popupTriggerBtnData = popupTriggerData?.relatedElementsTriggerCTA[0]?.properties
    ? parseNFButton(popupTriggerData?.relatedElementsTriggerCTA[0]?.properties)
    : undefined;
  const popupTriggerIcon = popupTriggerData?.relatedElementsTriggerCTAIcon;
  const popupTriggerActiveText = popupTriggerData?.relatedElementsTriggerActiveText;
  const popupListProps: IRelatedElementsCarouselData | undefined = useMemo(
    () =>
      relatedElementsCarouselHelpers.getRelatedElementsListProps(relatedElements, carouselControls),
    []
  );

  const handleResizePopupList = useCallback(() => {
    relatedElementsCarouselHelpers.resizeRelatedElementsList(
      isMobile,
      setShowScrollOnRelatedElements,
      popupListProps?.items.length
    );
  }, [popupListProps, isMobile]);

  useEffect(() => {
    const portalContainerElement = document.querySelector('.campaign-page-star-trek');
    if (portalContainerElement) {
      setPortalContainer(portalContainerElement as HTMLElement);
    }
  }, []);

  useEffect(() => {
    if (isPopupVisible) {
      handleResizePopupList();
    }
  }, [isPopupVisible]);

  useEffect(() => {
    if (isPopupVisible && elementRef.current) {
      elementRef?.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isPopupVisible]);

  useEffect(() => {
    handleCalculatedResponsiveObj(isDesktop || isLargeDesktop, windowWidth)();
  }, [windowWidth, isDesktop, isLargeDesktop]);

  return (
    <div
      className={classNames('card', { [`${classes}`]: classes })}
      style={
        bg &&
        ({
          backgroundColor: `var(--${bg})`,
        } as React.CSSProperties)
      }
    >
      {showLabels && label ? (
        <span
          className="nf-article-card__label"
          style={
            labelBgColor &&
            ({
              backgroundColor: `var(--${labelBgColor})`,
              color: `var(--${
                labelBgColor === 'navy' ? LABEL_LIGHT_FONT_TEXT : LABEL_DARK_FONT_TEXT
              })`,
            } as React.CSSProperties)
          }
        >
          {label}
        </span>
      ) : null}
      <div className="card-body">
        <div className="card-over-the-title">{descriptionOverTheTitle}</div>
        <div
          className="card-title"
          title={title}
          style={fontColor && ({ color: `var(--${fontColor})` } as React.CSSProperties)}
        >
          {title}
        </div>
        <div className="card-bulletlist">
          <DangerouslySetInnerHtml html={descriptionExtended || text} />
          {img?.gatsbyImage && (
            <UniversalImage
              img={img}
              imageAlt={alt}
              wrapperClassName="nf-card__img type--img"
              imgStyle={{ objectFit: 'contain' }}
              objectFitData={{ objectFit: 'contain' }}
              withDirectionClassName
              isLazyLoading={isLazyLoading}
            />
          )}
          {productRelatedImage?.gatsbyImage ? (
            <UniversalImage
              img={productRelatedImage}
              imageAlt={productRelatedImageAlt}
              wrapperClassName="nf-card__character-img"
              imgStyle={{ objectFit: 'contain' }}
              objectFitData={{
                objectFit: 'contain',
                objectPosition: '100% 100%',
              }}
            />
          ) : null}
        </div>
      </div>
      {!showBuyNowButton && (
        <Link className="nf-card-holder-link" to={link}>
          {defaultButtontext}
        </Link>
      )}
      <div className="footnotes">
        <DangerouslySetInnerHtml html={footnotes} />
      </div>
      {showBuyNowButton && popupTriggerBtnData ? (
        <NFButton
          {...popupTriggerBtnData}
          className="related-map-view-btn related-elements-trigger"
          icon={popupTriggerIcon}
          onClick={handleRelatedElementsVisibilityChange}
          customElement={isPopupVisible ? <span className="trigger-triangle" /> : null}
          btnText={isPopupVisible ? popupTriggerActiveText : popupTriggerBtnData.btnText}
          btnStyles={relatedElementsCarouselHelpers.getButtonStyles(
            isPopupVisible,
            popupTriggerBtnData
          )}
        />
      ) : null}
      {popupListProps &&
        isPopupVisible &&
        portalContainer &&
        ReactDOM.createPortal(
          <SimplePopup
            className={classNames('related-elements-popup', {
              'with-scroll': isShowScrollOnRelatedElements,
            })}
            closeButtonTitle={popupListProps.closeButtonTitle}
            closeButtonAriaLabel={popupListProps.closeButtonAriaLabel}
            handleVisibilityChange={handleRelatedElementsVisibilityChange}
            clickOutsideExceptionsIds={[RELATED_ELEMENTS_TRIGGER_ID]}
            withCloseBtn
          >
            <div ref={elementRef}>
              {windowWidth <= MOBILE_VIEW_RELATED_ELEMENTS ? (
                <RelatedElementsList {...popupListProps} />
              ) : (
                <RelatedElementsCarousel
                  {...popupListProps}
                  responsiveObjCalculated={responsiveObj}
                />
              )}
            </div>
          </SimplePopup>,
          portalContainer
        )}
    </div>
  );
};

export const query = graphql`
  fragment FragmentProductCardModifiedProps on IProductCard {
    sku
    link
    lang
    preferred {
      title
      id
    }
    descriptionLong
    descriptionShort
    descriptionExtended
    descriptionOverTheTitle
    footnotes
    defaultProductTitle
    defaultProductImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxHeight: 150, fit: CONTAIN, background: "rgba(0,0,0,0)") {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    productImageAlt
    productRelatedImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 256, fit: CONTAIN, background: "rgba(0,0,0,0)") {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    productRelatedImageAlt
    productLinks {
      properties {
        ...FragmentProductLink
      }
    }
    productFamily {
      ...FragmentProductFamily
    }
  }
`;

export default ProductCardOverride;

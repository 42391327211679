import React, { FC, useState, useEffect } from 'react';
import sortBy from 'lodash.sortby';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';

import ProductsCarousel from 'components/ProductsCarousel';
import { parseNFButton } from 'components/common/NFButton/parsers';
import { DEFAULT_PRODUCT_FAMILY_COLOR } from 'utils/constants';
import { getProductDescription } from 'utils/helpers';

import ProductCardModified from '../ProductCardModified';

import { RelatedProductsModifiedProps } from './model';
import responsiveObj from './constants';

import 'components/RelatedProducts/RelatedProducts.scss';
import './RelatedProductsModified.scss';

const RelatedProductsModified: FC<RelatedProductsModifiedProps> = ({
  title,
  subTitle,
  shopBtnsShow,
  sectionCtaBtn,
  showBuyNowButton,
  selectedProductsBody,
  selectedProducts,
  relatedElementsTrigger,
  relatedElements,
  carouselControls,
}) => {
  const [productsList, setProductsList] = useState<ProductCard.IProductCard[]>(
    selectedProductsBody.nodes
  );
  const [oneProduct, setOneProduct] = useState(false);
  const [multiProducts, setMultiProducts] = useState(false);

  useEffect(() => {
    if (selectedProducts?.length) {
      const urlToIndexMap = new Map();

      selectedProducts.forEach((item, index) => {
        urlToIndexMap.set(item.url, index);
      });

      const sortedArray = sortBy(selectedProductsBody.nodes, (item) => {
        const index = urlToIndexMap.get(item.link);

        return index;
      });

      setProductsList(sortedArray);
    } else {
      setProductsList(selectedProductsBody.nodes);
    }
  }, [selectedProductsBody.nodes, selectedProducts]);

  useEffect(() => {
    setOneProduct(productsList.length === 1);
    setMultiProducts(productsList.length > 2);
  }, [productsList.length]);

  return (
    <div
      className={classNames('related-products-wrapper related-products-modified-wrapper', {
        'related-products-modified-wrapper--multi': multiProducts,
      })}
      id="related-products-wrapper"
    >
      <Container fluid>
        <div className={classNames('', { 'related-products-modified-wrapper--one': oneProduct })}>
          <ProductsCarousel
            title={title}
            subtitle={subTitle}
            responsiveObj={responsiveObj}
            carouselClass="related-product"
            btn={parseNFButton(sectionCtaBtn[0].properties)}
            {...carouselControls}
          >
            {productsList.map(
              (
                {
                  sku,
                  label,
                  descriptionLong,
                  descriptionShort,
                  descriptionExtended,
                  descriptionOverTheTitle,
                  footnotes,
                  defaultProductTitle,
                  defaultProductImage,
                  productLinks,
                  productFamily,
                  link,
                  productImageAlt,
                  productRelatedImage,
                  productRelatedImageAlt,
                },
                id
              ) => (
                <ProductCardModified
                  key={sku}
                  label={label}
                  title={defaultProductTitle}
                  descriptionExtended={descriptionExtended}
                  descriptionOverTheTitle={descriptionOverTheTitle}
                  text={getProductDescription(descriptionLong, descriptionShort)}
                  img={defaultProductImage}
                  productRelatedImage={productRelatedImage}
                  productRelatedImageAlt={productRelatedImageAlt}
                  classes={classNames('nf-related-product-card nf-related-product-card-modified', {
                    'with-related-image': productRelatedImage,
                  })}
                  bg={
                    productFamily?.[0]?.relatedProductBg?.[0]?.properties.colorPicker?.label ||
                    DEFAULT_PRODUCT_FAMILY_COLOR
                  }
                  btn={productLinks?.[0]?.properties}
                  shopBtnShow={shopBtnsShow}
                  link={link}
                  alt={productImageAlt}
                  footnotes={footnotes}
                  relatedElementsTrigger={relatedElementsTrigger[id]}
                  relatedElements={relatedElements[id]}
                  showBuyNowButton={showBuyNowButton}
                  carouselControls={carouselControls}
                />
              )
            )}
          </ProductsCarousel>
        </div>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentRelatedProductsModifiedConfig on IRelatedProductsModifiedConfig {
    title
    subTitle
    shopBtnsShow
    sectionCtaBtn {
      properties {
        ...FragmentNFButton
      }
    }
    showBuyNowButton
    selectedProducts {
      url
    }
    relatedElementsTrigger {
      properties {
        ...FragmentRelatedElementsTrigger
      }
    }
    relatedElements {
      properties {
        ...FragmentRelatedElementsList
      }
    }
  }
`;

export default RelatedProductsModified;

import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import useScreenRecognition from 'gatsby-theme-nurofen/src/hooks/useScreenRecognition';

import UniversalImage from 'gatsby-theme-nurofen/src/components/common/UniversalImage';
import NFButton from 'gatsby-theme-nurofen/src/components/common/NFButton';
import DangerouslySetInnerHtml from 'gatsby-theme-nurofen/src/components/common/DangerouslySetInnerHtml';

import { parseNFButton } from 'gatsby-theme-nurofen/src/components/common/NFButton/parsers';
import { ISideImageBanner } from './model';

import './SideImageBanner.scss';

const SideImageBanner: FC<ISideImageBanner> = ({
  title,
  image,
  imageMobile,
  imageAlt,
  header,
  headerSmall,
  buttonLink,
  text,
  colorPicker,
  backgroundImage,
  backgroundImageAlt,
}) => {
  const { isTablet, isMobile } = useScreenRecognition({ isTablet: true });

  return (
    <section
      className={classNames('side-image-banner', {
        [`${colorPicker?.[0]?.properties?.colorPicker?.label}-default-bg`]:
          colorPicker && colorPicker.length > 0,
      })}
      style={
        colorPicker &&
        ({
          backgroundColor: `var(--${colorPicker?.[0]?.properties?.colorPicker?.label})`,
        } as React.CSSProperties)
      }
    >
      {backgroundImage ? (
        <div className="side-image-banner__background">
          <UniversalImage
            img={backgroundImage}
            imageAlt={backgroundImageAlt}
          />
        </div>
      ) : null}
      <div className="side-image-banner__container">
        <div className="side-image-banner__content">
          <div className="side-image-banner__header">
            {header ? (
              <div className="side-image-banner__header--main">
                <DangerouslySetInnerHtml html={header} />
              </div>
            ) : null}
            {headerSmall ? (
              <div className="side-image-banner__header--small">
                {headerSmall}
              </div>
            ) : null}
          </div>
          <h1 className="side-image-banner__title">{title}</h1>
          {text ? <p className="side-image-banner__text">{text}</p> : null}
          {buttonLink ? (
            <div className="side-image-banner__button">
              <NFButton
                type="submit"
                variant="primary"
                size="lg"
                {...parseNFButton(buttonLink[0].properties)}
              />
            </div>
          ) : null}
        </div>
        {image ? (
          <div className="side-image-banner__image">
            <UniversalImage
              img={isTablet || isMobile ? imageMobile : image}
              imageAlt={imageAlt}
            />
          </div>
        ) : null}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentSideImageBanner on ISideImageBanner {
    title
    image {
      fallbackUrl
    }
    imageMobile {
      fallbackUrl
    }
    imageAlt
    header
    headerSmall
    buttonLink {
      properties {
        ...FragmentNFButton
      }
    }
    text
    colorPicker {
      ...FragmentColorProps
    }
    backgroundImage {
      fallbackUrl
    }
    backgroundImageAlt
  }
`;

export default SideImageBanner;

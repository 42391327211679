import React from "react"

import ArticleSubNav from "gatsby-theme-nurofen/src/components/ArticleSubNav"
import PainBanner from "gatsby-theme-nurofen/src/components/PainBanner"
import Facts from "gatsby-theme-nurofen/src/components/Facts"
import TextWithImage from "gatsby-theme-nurofen/src/components/TextWithImage"
import Reports from "gatsby-theme-nurofen/src/components/Reports"
import Pledge from "gatsby-theme-nurofen/src/components/Pledge"
import ReadReportSection from "gatsby-theme-nurofen/src/components/ReadReportSection"
import TextSection from "gatsby-theme-nurofen/src/components/TextSection"
import Timeline from "gatsby-theme-nurofen/src/components/Timeline"
import PainBannerExtendTitle from "gatsby-theme-nurofen/src/components/PainBannerExtendTitle"
import { formatAnchor } from "gatsby-theme-nurofen/src/utils/helpers"
import RelatedProductsModified from "gatsby-theme-nurofen/src/components/RelatedProductsModified"

import SideImageBanner from "../../components/SideImageBanner/SideImageBanner"
import SimpleListComponent from "../../components/SimpleListComponent/SimpleListComponent"
import TwoColumnListing from "../../components/TwoColumnListing/TwoColumnListing"
import StarTrekBannerSmall from "../../components/StarTrekBannerSmall"

const bodySections: AppPageDataTypes.TBodyElements = {
  "Pain Banner": ({ title, bannerImage, imageAlt }, keyId) => (
    <PainBanner
      key={keyId}
      title={title}
      image={bannerImage}
      imageAlt={imageAlt}
    />
  ),
  "Article Anchor Navigation": (
    { title, description, sectionAnchors, bulletColor },
    keyId
  ) => (
    <ArticleSubNav
      key={keyId}
      colWidth={{ md: 11 }}
      title={title}
      description={description}
      sections={sectionAnchors}
      bulletColor={bulletColor[0].properties.colorPicker?.label || "red"}
    />
  ),
  "Facts Section": ({ title, facts, sectionAnchor }, keyId) => (
    <Facts
      title={title}
      facts={facts}
      sectionAnchor={formatAnchor(sectionAnchor)}
      key={keyId}
    />
  ),
  "Reports Section": (
    { title, description, reports, sectionAnchor, sectionBackground },
    keyId
  ) => (
    <Reports
      key={keyId}
      title={title}
      description={description}
      reports={reports}
      sectionAnchor={formatAnchor(sectionAnchor)}
      sectionBackground={sectionBackground}
    />
  ),
  "Text with Image Section": ({ content, sectionAnchor }, keyId) => (
    <TextWithImage
      key={keyId}
      content={content}
      sectionAnchor={formatAnchor(sectionAnchor)}
    />
  ),
  "Text Item": ({ text }, keyId) => <TextSection key={keyId} text={text} />,
  "Pledge Section": (
    { title, description, items, sectionAnchor, sectionBackground },
    keyId
  ) => (
    <Pledge
      key={keyId}
      title={title}
      description={description}
      items={items}
      sectionAnchor={formatAnchor(sectionAnchor)}
      sectionBackground={sectionBackground}
    />
  ),
  "Read Report Section": (
    {
      title,
      description,
      sectionAnchor,
      image,
      imageAlt,
      anchorText,
      fileLink,
      icon,
      sectionBackground,
    },
    keyId
  ) => (
    <ReadReportSection
      key={keyId}
      title={title}
      description={description}
      sectionAnchor={formatAnchor(sectionAnchor)}
      image={image}
      imageAlt={imageAlt}
      anchorText={anchorText}
      fileLink={fileLink}
      icon={icon}
      sectionBackground={sectionBackground}
    />
  ),
  "Timeline Section": (
    {
      title,
      subtitle,
      description,
      timelineItems,
      sectionAnchor,
      sectionBackground,
    },
    keyId
  ) => (
    <Timeline
      key={keyId}
      title={title}
      subtitle={subtitle}
      description={description}
      timelineItems={timelineItems}
      sectionAnchor={formatAnchor(sectionAnchor)}
      sectionBackground={sectionBackground}
    />
  ),
  "Pain Banner Extend Title": (
    {
      title,
      header,
      headerSmall,
      image,
      imageMobile,
      imageAlt,
      buttonLink,
      text,
    },
    keyId
  ) => (
    <PainBannerExtendTitle
      key={keyId}
      title={title}
      header={header}
      headerSmall={headerSmall}
      image={image}
      imageMobile={imageMobile}
      imageAlt={imageAlt}
      buttonLink={buttonLink}
      text={text}
    />
  ),
  "Side Image Banner": (
    {
      title,
      image,
      imageMobile,
      imageAlt,
      header,
      headerSmall,
      buttonLink,
      text,
      colorPicker,
      backgroundImage,
      backgroundImageAlt,
    },
    keyId
  ) => (
    <div>
      <SideImageBanner
        key={keyId}
        title={title}
        header={header}
        headerSmall={headerSmall}
        image={image}
        imageMobile={imageMobile}
        imageAlt={imageAlt}
        buttonLink={buttonLink}
        text={text}
        colorPicker={colorPicker}
        backgroundImage={backgroundImage}
        backgroundImageAlt={backgroundImageAlt}
      />
    </div>
  ),
  "Simple List Component": ({ simpleListComponent }, keyId) => (
    <SimpleListComponent
      key={keyId}
      simpleListComponent={simpleListComponent}
    />
  ),
  "Two Column Listing": ({ twoColumnListing, colorPicker }, keyId) => (
    <TwoColumnListing
      key={keyId}
      twoColumnListing={twoColumnListing}
      colorPicker={colorPicker}
    />
  ),
  "Star Trek Banner Small": ({ starTrekBannerSmall, colorPicker, backgroundImageSmall, backgroundImageAltSmall }, keyId) => (
    <StarTrekBannerSmall
      key={keyId}
      starTrekBannerSmall={starTrekBannerSmall}
      colorPicker={colorPicker}
      backgroundImageSmall={backgroundImageSmall}
      backgroundImageAltSmall={backgroundImageAltSmall}
    />
  ),
  "Related Products Modified": (
    {
      title,
      carouselControls,
      selectedProductsBody,
      selectedProducts,
      relatedElements,
      relatedElementsTrigger,
      shopBtnsShow,
      sectionCtaBtn,
      showBuyNowButton,
    },
    keyId
  ) => (
    <RelatedProductsModified
      key={keyId}
      title={title}
      carouselControls={carouselControls}
      selectedProducts={selectedProducts}
      selectedProductsBody={selectedProductsBody}
      relatedElements={relatedElements}
      relatedElementsTrigger={relatedElementsTrigger}
      shopBtnsShow={shopBtnsShow}
      sectionCtaBtn={sectionCtaBtn}
      showBuyNowButton={showBuyNowButton}
    />
  ),
}

export default bodySections

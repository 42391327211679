import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import DangerouslySetInnerHtml from 'gatsby-theme-nurofen/src/components/common/DangerouslySetInnerHtml';
import { ITwoColumnListing } from './model';

import './TwoColumnListing.scss';

const TwoColumnListing: FC<ITwoColumnListing> = ({
  twoColumnListing,
  colorPicker,
}) => {
  return (
    <section
      className={classNames('two-column-listing', {
        [`${colorPicker?.[0]?.properties?.colorPicker?.label}-default-bg`]:
          colorPicker && colorPicker.length > 0,
      })}
      style={
        colorPicker &&
        ({
          backgroundColor: `var(--${colorPicker?.[0]?.properties?.colorPicker?.label})`,
        } as React.CSSProperties)
      }
    >
      <DangerouslySetInnerHtml html={twoColumnListing} />
    </section>
  );
};

export const query = graphql`
  fragment FragmentTwoColumnListing on ITwoColumnListing {
    twoColumnListing
  }
`;

export default TwoColumnListing;

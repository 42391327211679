import React, { FC } from "react"
import { graphql } from "gatsby"
import classNames from "classnames"
import DangerouslySetInnerHtml from "gatsby-theme-nurofen/src/components/common/DangerouslySetInnerHtml"
import UniversalImage from 'gatsby-theme-nurofen/src/components/common/UniversalImage';
import { IStarTrekBannerSmall } from "./model"

import "./StarTrekBannerSmall.scss"

const StarTrekBannerSmall: FC<IStarTrekBannerSmall> = ({
  starTrekBannerSmall,
  colorPicker,
  backgroundImageSmall,
  backgroundImageAltSmall,
}) => {
  return (
    <section
      className={classNames("star-trek-banner-small", {
        [`${colorPicker?.[0]?.properties?.colorPicker?.label}-default-bg`]:
          colorPicker && colorPicker.length > 0,
      })}
      style={
        colorPicker &&
        ({
          backgroundColor: `var(--${colorPicker?.[0]?.properties?.colorPicker?.label})`,
        } as React.CSSProperties)
      }
    >
      <div className="star-trek-banner-small__container">
        <DangerouslySetInnerHtml html={starTrekBannerSmall} />
      </div>
      

      {backgroundImageSmall ? (
        <div className="star-trek-banner-small__background">
          <UniversalImage
            img={backgroundImageSmall}
            imageAlt={backgroundImageAltSmall}
          />
        </div>
      ) : null}
    </section>
  )
}

export const query = graphql`
  fragment FragmentStarTrekBannerSmall on IStarTrekBannerSmall {
    starTrekBannerSmall
    backgroundImageSmall {
      fallbackUrl
    }
    backgroundImageAltSmall
  }
`

export default StarTrekBannerSmall
